@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');
* {
  font-family: 'Lato', sans-serif;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background-color: #131c25;
}

html {
  scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6, span {
  color: #242424;
  text-align: center;
  line-height: 1.25;
}

h1 {
  font-size: 36px;
}

p {
  line-height: 1.5;
  font-size: 16px;
}

a {
  text-decoration: none;
  position: relative;
  transition: opacity 300ms ease;
}

i:hover {
  opacity: 0.7;
}

.link__hover-effect:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  bottom: -3px;
  right: 0;
  transition: all 300ms ease;
}

.link__hover-effect--white:after {
  background-color: #fff;
}
.link__hover-effect--black:after {
  background-color: gray;
}

.link__hover-effect:hover:after {
  width: 100%;
  left: 0;
}

li {
  list-style-type: none;
}

.flex {
  display: flex;
}

.flex-1 {
  flex: 1;
}

.container {
  padding: 50px 0;
}

.containerProjects {
  padding: 50px 0;
  animation: fade-in 1200ms 1500ms backwards;
}

.row {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 12px;
}

.text--purple {
  color: #82a9ff;
}

.text--purple2 {
  color: #82a9ff;
}

section:nth-child(even) {
  background-color: #1E2933;
}

.section__title {
  color: lightgray;
  margin-bottom: 20px;
  animation: fade-in 1200ms 1200ms backwards;
}

/* NAVIGATION BAR */

nav {
  height: 100px;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
}

.nav__link--list {
  display: flex;
}

.nav__link--anchor {
  margin: 0 12px;
  color: lightgray;
  font-weight: 700;
  cursor: pointer;
}

.nav__link--anchor-primary {
  background-color: #396ee2;
  padding: 8px 20px;
  border-radius: 50px;
  color: white;
  transition: all 300ms ease;
}

.nav__link--anchor-primary2 {
  background-color: #396ee2;
  padding: 5px 20px;
  border-radius: 50px;
  color: white;
  transition: all 300ms ease;
  font-size: 22px;
  color: #242424;
}

.nav__link--anchor-primary:hover {
  color: white;
  background-color: #0d59ff;
}

.personal__logo {
  font-size: 24px;
  color: #82a9ff;
  margin: 0 12px;
  font-weight: bold;
  cursor: pointer;
}

/* ABOUT ME */

#about-me {
  min-height: 85vh;
  display: flex;
  flex-direction: column;
}

.about-me__info {
  display: flex;
  flex-direction: column;
}

/* PROJECT PAGE TEST */

#overview{
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#features{
  min-height: 75vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project__info {
  display: flex;
  flex-direction: column;
}

.project__info--title {
  color: white;
  margin-bottom: 16px;
  margin-top: 20px;
  font-size: 45px;
  animation: fade-up 650ms 400ms backwards;
}

.project__info--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* max-width: 1000px; */
  margin: 0 auto;
  text-align: center;
}

.project__picture--mask {
  flex: 1;
  /* box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  margin-bottom: 28px;
  margin-top: 20px; */
  animation: animate-profile-picture 800ms 200ms backwards;
}

.project__picture {
  width: 90%;
  transform: scale(1);
  border-radius: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
}

.project__info-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5%;
  margin-bottom: 7%;
}

.row2 {
  width: 90%;
  max-width: 1800px;
  margin: 10px auto;
  padding: 0 12px;
}

.project__info--para {
  color: lightgray;
  flex: 1;
  padding-left: 5%;
  padding-right: 5%;
  font-size: 20px;
  margin-bottom: 28px;
  animation: fade-up 650ms 600ms backwards;
}

.project__tech--list{
  display: flex;
  padding-top: 5px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  line-height: 2
}

.project__tech--container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tech_logo{
  width: 30px;
  padding-right: 7px;
  margin-left: 10px;
}

.project_features--container {
  padding: 50px 0;
  animation: fade-in 1200ms 1500ms backwards;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project_features--one{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}

.project_features--one2{
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  margin-top: 3%;
  margin-bottom: 3%;
}

.project_features--video{
  width: 30%;
  border-radius: 15px;
  margin-left: 12%;
}

.project_features--videoContainer{
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  margin-top: 7%;
  margin-bottom: 5%;
}

.projectImageContainer{
  width: 100%;
  display: flex;
  align-items: center;
}

.projectImage{
  width: 85%;
}

.project-feature--details{
  width: 80%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.sec_log{
  height: 100%;
}

.project_feature--title{
  color: white;
  text-align: start;
  font-size: 45px;
  margin-bottom: 20px;
}

.project-feature--single{
  color: lightgray;
  text-align: start;
  font-size: 20px;
  font-weight: 600;
  padding-top: 5px;
  padding-bottom: 5px;
}

.featureDivider{
  height: 1px;
  background-color: #636364;
  width: 35%;
  margin-top: 2%;
  margin-bottom: 2%;
}

.iphoneFrame{
  position: absolute;
  width: 33.5%;
  left: 56%;
  top: -2.5%;
  transform: translate(-50%, 0);
}

.iphoneFrameLeft{
  position: absolute;
  width: 33.5%;
  left: 20%;
  top: -2.5%;
  transform: translate(-50%, 0);
}

.iphoneFrameRight{
  position: absolute;
  width: 33.5%;
  left: 67%;
  top: -2.5%;
  transform: translate(-50%, 0);
}

.project_features--videoContainerDuo{
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-between;
  margin-top: 7%;
  margin-bottom: 5%;
}


.project_features--videoLeft{
  width: 30%;
  border-radius: 15px;
  margin-left: 5%;
}

.project_features--videoRight{
  width: 30%;
  border-radius: 15px;
  margin-right: 18%;
}

/* PROJECT PAGE TEST */


.about-me__info--container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  text-align: center;
}

.about-me__picture--mask {
  width: 135px;
  height: 135px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.16);
  margin-bottom: 28px;
  animation: animate-profile-picture 800ms 200ms backwards;
}

@keyframes animate-profile-picture {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.about-me__picture {
  width: 90%;
  transform: scale(1.4);
  padding-top: 8px;
}

.about-me__info--title {
  color: white;
  margin-bottom: 16px;
  animation: fade-up 650ms 400ms backwards;
}
.about-me__info--para {
  color: lightgray;
  font-size: 20px;
  margin-bottom: 28px;
  animation: fade-up 650ms 600ms backwards;
}

.about-me__link {
  font-size: 20px;
  color: #242424;
  padding: 0 16px;
}

.about-me__links {
  animation: fade-up 650ms 800ms backwards;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-me__img--container {
  flex: 1;
  padding-top: 7%;
  padding-bottom: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.about-me__img {
  width: 80%;
  animation: fade-in 1200ms 800ms backwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wave {
  display: inline-block;
  animation: animate-wave 500ms infinite ease-in-out;
}

.headset{
  display: inline-block;
}

@keyframes animate-wave {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* TECH - STACK */

.language__img {
  width: 100%;
  max-width: 100px;
  transition: all 300ms;
}
.language__img2 {
  width: 100%;
  max-width: 140px;
  transition: all 300ms;
}
.language:hover .language__img {
  filter: brightness(80%);
  opacity: 0.86;
  transform: scale(0.9);
}
.language:hover .language__img2 {
  filter: brightness(80%);
  opacity: 0.86;
  transform: scale(0.9);
}

.language {
  width: 25%;
  display: flex;
  justify-content: center;
  position: relative;
}
.language__img--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px 16px;
}

.language__list {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  animation: fade-in 1200ms 1300ms backwards;
}

.language__name {
  color: white;
  position: absolute;
  bottom: 0;
  transform: scale(0);
  transition: all 300ms;
  font-size: 20px;
  opacity: 0;
}
.language:hover .language__name {
  transform: scale(1);
  opacity: 1;
}

/* PROJECTS */

.project {
  margin-bottom: 135px;
}

.project:last-child {
  margin-bottom: 40px;
}

.project__img {
  width: 100%;
  transition: all 500ms ease;
}

.project__wrapper {
  display: flex;
  box-shadow: 0 20px 80px rgba(0, 0, 0, 0.45);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
}
.project__wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1c1d25;
  opacity: 0;
  transition: all 450ms ease;
  z-index: 2;
  transform: translateY(100%);
}
.project:hover .project__wrapper:before {
  transform: translateY(0);
  opacity: 0.7;
}
.project:hover .project__img {
  transform: scale(1.07);
  filter: blur(5px);
}

.project:hover .project__description {
  opacity: 1;
  transform: translateY(-50%);
}

.project__list {
  padding-top: 40px;
}

.project__description {
  position: absolute;
  top: 50%;
  left: 90px;
  transform: translateY(100%);
  max-width: 550px;
  z-index: 3;
  opacity: 0;
  transition: transform 450ms, opacity 300ms;
}

.project__description--title-container{
  display: flex;
  flex-direction: row;
  align-items: center;
}

.sec-notice {
  background-color: rgba(6, 140, 249, 0.25);
  border-radius: 20px;
  padding: 2px 10px;
  display: inline-block;
  margin-left: 7px;
  margin-top: 5px;
}

.sec-notice-text{
  color: #fff;
  font-size: 13px;
}

.project__description--title {
  font-size: 40px;
}

.project__description--para {
  margin: 16px 0;
}

.project__description--links {
  display: flex;
}

.project__description--link {
  font-size: 20px;
  margin-right: 16px;
  display: flex;
}

.project__description--linkText {
  display: flex;
  font-size: 15px;
  margin-right: 5px;
}

.project__description--title,
.project__description--sub-title,
.project__description--para,
.project__description--link {
  text-align: left;
  color: #fff;
}

/* FOOTER */

footer {
  background-color: #1f2a35;
}

.footer2{
  background-color: #253341;
}

.footer__social--list {
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: space-around;
  margin-bottom: 28px;
}
.footer__row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0;
  max-height: 200px
}

.footer__logo--img {
  width: 70px;
  height: 70px;
}

.footer__social--link,
.footer__copyright,
.footer__logo--popper {
  color: #fff;
}

.footer__logo--popper {
  position: absolute;
  right: 0;
  top: 30px;
  font-weight: 700;
  opacity: 0;
  transition: all 300ms ease;
}

.footer__anchor {
  margin-bottom: 20px;
  position: relative;
}

.footer__anchor:hover .footer__logo--popper {
  transform: translateX(60px);
  opacity: 1;
}

/* smaller display */
@media (max-width: 1500px) {
  .project__info--para {
    font-size: 16px;
  }

  .text--purple2{
    font-size: 16px;
  }

  .tech_logo{
    width: 25px;
  }
}

/* smaller display */
@media (max-width: 1200px) {

  .project__info-row{
    flex-direction: column;
    margin-top: 2%;
  }

  .project__picture--mask{
    padding-bottom: 2%;
  }

  .project__picture{
    width: 60%;
  }

  .project__info--para {
    padding-top: 2%;
    font-size: 18px;
    width: 90%;
  }

  .text--purple2{
    font-size: 18px;
  }

  .tech_logo{
    width: 25px;
  }

  .project_features--one{
    width: 90%;
  }
  
  .project_feature--title{
    font-size: 30px;
  }

  .project-feature--single{
    font-size: 20px;
  }
}

@media (max-width: 1000px) {

  .project_feature--title{
    font-size: 25px;
  }

  .project-feature--single{
    font-size: 17px;
  }
}

/* Small phones, tablets, large smartphones */
@media (max-width: 768px) {

  .row2{
    width: 100%;
  }

  .project__picture{
    width: 80%;
  }

  .project__info--para{
    font-size: 17px;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .project__tech--list{
    margin-top: 2%;
    text-align: center;
    width: 120%;
  }

  .text--purple2{
    font-size: 17px;
  }

  .project_features--one{
    flex-direction: column;
  }

  .project_features--one2{
    flex-direction: column;
  }

  .projectImageContainer{
    justify-content: center;
    margin-bottom: 7%;
  }

  .projectImage{
    width: 110%;
  }

  .project_features--videoContainer{
    align-items: center;
    justify-content: center;
    margin-bottom: 10%;
  }

  .iphoneFrame{
    left: 50%
  }

  .iphoneFrameLeft{
    position: absolute;
    width: 33.5%;
    left: 25%;
    top: -2.5%;
    transform: translate(-50%, 0);
  }
  
  .iphoneFrameRight{
    position: absolute;
    width: 33.5%;
    left: 75%;
    top: -2.5%;
    transform: translate(-50%, 0);
  }

  .project_features--videoLeft{
    width: 30%;
    border-radius: 15px;
    margin-left: 10%;
  }
  
  .project_features--videoRight{
    width: 30%;
    border-radius: 15px;
    margin-right: 10%;
  }

  .project_features--video{
    margin-left: 0%;
  }

  .featureDivider{
    margin-top: 10%;
  }

  .project-feature--details{
    align-items: center;
    width: 100%;
  }

  .project_feature--title{
    text-align: center;
  }

  .project-feature--single{
    text-align: center;
  }

  nav {
    height: 68px;
  }

  h1 {
    font-size: 28px;
  }

  .about-me__info--para {
    font-size: 18px;
  }

  .language {
    width: calc(100% / 3);
  }

  .project__description--para {
    font-size: 14px;
  }

  .project__description {
    left: 30px;
    padding-right: 30px;
  }
}

/* SMALL PHONES */
@media (max-width: 480px) {

  .project__info--para{
    font-size: 15px;
  }

  .text--purple2{
    font-size: 15px;
  }

  .nav__link:not(:last-child) {
    display: none;
  }

  .project__wrapper {
    border-radius: 0;
  }

  .project__description {
    padding: 0;
    left: 0;
    width: 100%;
  }

  .project__description--para {
    display: none;
  }

  .project__description--links {
    display: flex;
    justify-content: center;
  }

  .project__description--title {
    font-size: 32px;
    line-height: 1;
    text-align: center;
  }

  .project__description--sub-title {
    text-align: center;
    margin: 12px 0;
  }
}

.page{
  background-color: #131c25;
}
.fa{
  color: white;
}


